<template>
  <el-main>
    <span>
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addRole">添加角色</el-button>
    </span>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#f8f9fa' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="role_id" label="ID" width="220" align="center"></el-table-column>
      <el-table-column prop="role_name" label="角色名称" align="center"></el-table-column>
      <el-table-column prop="role_status" label="状态" align="center">
        <template v-slot="{ row }">
          {{ row.role_status ? '正常' : '禁用' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="remove(scope.row.role_id, scope.$index)" :disabled="scope.row.is_default == 1 ? true : false" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging class="paging" :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-drawer title="添加/编辑角色" ref="drawer" :visible.sync="drawer" size="700px" direction="rtl" custom-class="demo-drawer" :before-close="handleClose">
      <div class="demo-drawer__content">
        <el-form label-width="120px">
          <el-form-item label="角色名称：">
            <el-input v-model="rolesForm.role_name"></el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-radio-group v-model="rolesForm.role_status">
              <el-radio :label="1">正常</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="分配权限：">
            <el-tree ref="tree" :data="treeList" :default-expanded-keys="defaultExpandedKeys" show-checkbox node-key="auth_id" :props="defaultProps"></el-tree>
          </el-form-item>
        </el-form>
        <div class="demo-drawer__footer">
          <el-button @click="$refs.drawer.closeDrawer()">取 消</el-button>
          <el-button type="primary" @click="roleSubmit" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
        </div>
      </div>
    </el-drawer>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
export default {
  components: { Paging },
  data() {
    return {
      list: [],
      page: 1,
      rows: 10,
      treeList: [],
      total_number: 0,
      handelRolesType:1,
      drawer: !1,
      rolesForm: {
        role_name: '',
        role_auth: '',
        role_status: 1,
      },
      defaultExpandedKeys: [],
      defaultProps: {
        children: 'childs',
        label: 'auth_name',
      },
    };
  },
  created() {
    this.getList();
    this.getAuths();
  },
  methods: {
    getList() {
      this.$axios
        .post(this.$api.store.rolesList, {
          page: this.page,
          rows: this.rows,
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list;
            for (let i in list) list[i].state = list[i].state ? !0 : !1;
            this.list = list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //获取菜单
    getAuths() {
      this.$axios.post(this.$api.user.getAuths).then((res) => {
        if (res.code == 0) {
          this.treeList = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //提交角色表单
    roleSubmit() {
      let rolesForm = this.rolesForm;
      let id = this.$refs.tree.getCheckedKeys();
      let treeList = this.treeList;
      for (let i in treeList) {
        let childs = treeList[i].childs || [];
        for (let y in childs) {
          for (let k in id) {
            if (id[k] == childs[y].auth_id) {
              id.push(treeList[i].auth_id);
            }
          }
        }
      }
      id = [...new Set(id)];
      id.sort(function (a, b) {
        return a - b;
      });
      id = id.join(',');
      if (!rolesForm.role_name) return this.$message.warning('请填写角色名称');
      if (!id) return this.$message.warning('请选择分配权限');
      rolesForm.role_auth = id;
      let url = this.handelRolesType ? this.$api.store.addRoles : this.$api.store.editRoles
      this.$axios.post(url, rolesForm).then((res) => {
        if (res.code == 0) {
          this.getList();
          this.drawer = !1;
          this.$message.success(this.handelRolesType ? '添加成功' : '编辑成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addRole() {
      this.drawer = !0;
      this.rolesForm.role_name = '';
      this.rolesForm.role_auth = '';
      this.rolesForm.role_status = 1;
      this.handelRolesType = 1;
    },
    //处理编辑角色信息
    edit(row) {
      let treeList = this.treeList;
      this.rolesForm.role_name = row.role_name;
      var tree_id = row.role_auth.split(',');
      var selectTree = [];
      this.defaultExpandedKeys = tree_id;
      for (let i in tree_id) tree_id[i] = Number(tree_id[i]);
      for (let i in treeList) {
        var childs = treeList[i].childs || [];
        for (let y in childs) {
          tree_id.map((id) => {
            if (id == childs[y].auth_id) {
              let obj = {
                label: childs[y].auth_name,
                auth_id: id,
              };
              selectTree.push(obj);
            }
          });
        }
      }
      this.drawer = !0;
      setTimeout(() => {
        this.$refs.tree.setCheckedNodes(selectTree);
      }, 50);
      this.handelRolesType = 0;
      this.rolesForm.role_status = row.role_status;
      this.rolesForm.role_id = row.role_id;
    },
    //改变状态
    upStatusEnclosure(row) {
      this.$axios.post(this.$api.push.upStatusEnclosure, { id: row.id }).then((res) => {
        if (res.code == 0) {
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    remove(role_id, index) {
      this.$confirm('确认删除此角色？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.store.delRoles, {
            role_id: role_id,
          })
          .then((res) => {
            if (res.code == 0) {
              this.list.splice(index, 1);
              this.$message({
                message: '删除成功',
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    cancelSearch() {
      this.trading_area = '';
      this.status = 0;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  font-size: 14px;
  background: #fff;
  display: flex;
  flex-direction: column;
  .el-table {
    flex: 1;
    margin-top: 15px;
  }
  /deep/ .demo-drawer {
    overflow-y: scroll;
    padding-bottom: 50px;
  }
  .demo-drawer__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .el-form-item .el-input {
      width: 500px;
    }
    .demo-drawer__footer {
      padding: 5px 10px;
      width: 700px;
      position: fixed;
      border-top: 1px solid #ebf0f3;
      background: #fff;
      display: flex;
      z-index: 10;
      justify-content: flex-end;
      bottom: 0;
      right: 0;
    }
  }
}
</style>
